<template>
  <v-container fluid>
    <v-alert v-if="!appData.configured" class="white--text my-3 text-subtitle-1 font-weight-bold" color="error">  {{ this.error }} 
      <v-btn class="ml-16" @click="$router.push('/configureClient')"> Configure Client </v-btn>
    </v-alert>
    <v-card elevation="0" class="px-3">
      <v-card-title class="text-h4">
        Group Management
        <v-spacer></v-spacer>
        <addNewGroup v-if="!isClientAdmin"></addNewGroup>
      </v-card-title>
    </v-card>
    <v-card elevation="0" class="px-3 mt-3">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search User Group by Name" single-line
          hide-details></v-text-field>
      </v-card-title>
      <v-data-table :loading="loading" :headers="getHeaders" :items="userGroups" :search="search">
        <template v-slot:item.name="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:item.userCount="{ item }">
          {{ item.userCount }}
        </template>
        <template v-slot:item.dateCreated="{ item }">
          {{ item.dateCreated | formatDate }}
        </template>
        <template v-slot:item.lastUpdated="{ item }">
          {{ item.lastUpdated | formatDate }}
        </template>
        <template v-slot:item.description="{ item }">
          {{ item.description | trimLength }}
        </template>
        <template v-slot:item.actions="{ item }">
          <editAndDeleteGroup :groupIndex="item"> </editAndDeleteGroup>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import addNewGroup from "@/components/dialogBox/groups/addNewGroup.vue";
import editAndDeleteGroup from '@/components/dialogBox/groups/editAndDeleteGroup.vue'
export default {
  name: "groupManagement",
  components: {
    addNewGroup,
    editAndDeleteGroup
  },
  data() {
    return {
      loading: true,
      search: "",
      error: 'Please configure the Client first. Click on the Configure Client button.',
      headers: [
        { text: "Group Name", align: "start", value: "name" },
        { text: "Description", value: "description" },
        { text: "Users", value: "userCount" },
        { text: "Last Updated", value: "lastUpdated" },
        { text: "Date Created", value: "dateCreated" },

      ],
      superAdminHeaders: [
        { text: "Group Name", align: "start", value: "name" },
        { text: "Description", value: "description" },
        { text: "Users", value: "userCount" },
        { text: "Last Updated", value: "lastUpdated" },
        { text: "Date Created", value: "dateCreated" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["userGroups", "assignedRole", "appData", "isClientAdmin"]),
    getHeaders() {
      if (this.assignedRole.data.permissions.orgGrp.delete || this.assignedRole.data.permissions.orgGrp.write) {
        return this.superAdminHeaders;
      } else {
        return this.headers;
      }
    },
  },
  methods: {
    openDialog() {
      console.log('What is there in appData: ', this.appData)
      if (this.appData.configured) {
        this.$store.commit('setNewGroupDialog', true)
      } else {
        this.error = 'Please configure the Client first. Click on the Configure Client option from Navigation window on the left.'
      }
    }
  },
  mounted() {
    this.loading = false
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
    trimLength(val) {
      if (val.length < 200) {
        return val;
      }
      return `${val.substring(0, 50)}...`;
    },
  },
};
</script>